<template>
	<CollapseMenu
		v-model="collapseMenu"
		:class="['chat-menu', { 'chat-menu--minimize': collapseMenu }]"
		:collapsedWidth="'76px'">
		<template #header>
			<NuxtLink
				to="/"
				class="chat-menu__logo"
				title="Главная">
				<div class="chat-menu__logo-image">
					<Logo />
				</div>

				<span class="link link--black chat-menu__link">Агрохелпер Агенты</span>
			</NuxtLink>
		</template>

		<div>
			<p
				v-if="getChatListError && !collapseMenu"
				class="chat-menu__message">
				{{ getChatListError }}
			</p>

			<div
				v-else-if="!getChatListError && !collapseMenu"
				class="search-input">
				<img
					class="search-icon"
					src="@/assets/icons/search.svg"
					alt="Декоративная иконка" />

				<input
					v-model="searchName"
					type="text"
					placeholder="Поиск ФИО" />

				<UiButton
					v-if="searchName.length"
					class="search-reset"
					:name="''"
					size="xs"
					btnColor="transparent"
					:icon-name="'close'"
					@click="onSearchReset" />
			</div>

			<template v-if="getChatList?.data.length">
				<PageChatSenderProfile
					v-for="(item, index) in getChatList.data"
					:key="item.id"
					:user="item"
					:index="index"
					:is-collapse="collapseMenu" />
			</template>

			<p
				v-else-if="!getChatList?.data.length && getChatSearchValue.length && !collapseMenu"
				class="chat-menu__message">
				По вашему запросу ничего не найдено
			</p>
		</div>
	</CollapseMenu>
</template>

<script setup lang="ts">
	import { useState } from "nuxt/app";

	const {
		chatListStore,
		chatListStore: { fetchChats, setSearch },
	} = useStores();

	const { getChatList, getChatListError, getChatSearchValue } = storeToRefs(chatListStore);

	if (!getChatList.value.data.length) await fetchChats();

	const { $debounce } = useNuxtApp();

	const collapseMenu = useState("chatMenuCollapse", () => false);

	const searchName = useState("chat-list-search-name", () => "");

	const onSearchReset = (): string => (searchName.value = "");

	const setSearchQuery = ($debounce as any)(async (value: string): Promise<void> => {
		await setSearch(value);
	}, 500);

	watch(
		() => searchName.value,
		(value) => setSearchQuery(value),
	);
</script>

<style lang="scss" scoped>
	@import "chat-menu";
</style>
